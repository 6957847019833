import React from "react";
import styled from "styled-components";

import internalLinks from "./hash.json";

const Subheader = styled.h2`
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 18px;
`;

const TpLinkSection = (): JSX.Element => (
    <section id={internalLinks.tplinks}>
        <Subheader>4. Third-Party Links</Subheader>
        <p>
            Some of the Services may contain links to content maintained that we
            do not control. We are not responsible for the privacy parties of
            these third parties or the content on the third-party services, and
            the information practices of these third parties are not covered by
            this Policy. Please note that these third-party services have their
            own privacy policies, and we strongly recommend that you read their
            privacy policies as terms and conditions of use to understand how
            they collect, use, and share your information.
        </p>
    </section>
);

export default TpLinkSection;
