import React from "react";
import styled from "styled-components";

import internalLinks from "./hash.json";

const Subheader = styled.h2`
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 18px;
`;

const TransferSection = (): JSX.Element => (
    <section id={internalLinks.transfer}>
        <Subheader>8. Transfer of Personal Information</Subheader>
        <p>
            Our Services are currently designed for use only in certain select
            markets. If you are using our Services from another jurisdiction,
            your information collected through our Services may be stored and
            processed in the United States or any other country in which
            dClimate or its affiliates maintain facilities or conduct business
            activities. If you are located in other regions with laws governing
            data collection and use that may differ from U.S. law, please not
            that we may transfer information, including personal information, to
            a country and jurisdiction that does not have the same
            data-protection laws as your jurisdiction.
        </p>
    </section>
);

export default TransferSection;
