import React from "react";
import styled from "styled-components";

import internalLinks from "./hash.json";

const Subheader = styled.h2`
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 18px;
`;

const List = styled.ol`
    list-style-type: decimal;
    padding: 18px 36px;
`;

const TypesOfInfoSection = (): JSX.Element => (
    <section id={internalLinks.types}>
        <Subheader>
            1. Types of Information Collected and How We Collect Them.
        </Subheader>
        <h3>Personal Information You Provide:</h3>
        <p>
            We collect the following personal information you provide when you
            use our services, like when you sign up for an account, request a
            transaction, enroll in a promotion or program, to otherwise engage
            or communicate with us:
        </p>
        <List>
            <li>
                <span>Identity Data</span>&nbsp;includes your full name, date of
                birth, gender, social security numbers, and other data on
                government-issued identification documents.
            </li>
            <li>
                <span>Contact Data</span>&nbsp;includes your email address,
                mailing address, and telephone numbers.
            </li>
            <li>
                <span>Profile Data</span>&nbsp;includes your username and
                password, purchases or orders made by you, your knowledge
                assessment results, and your interests, preferences, feedback,
                and survey responses.
            </li>
            <li>
                <span>Additional Information</span>&nbsp;includes information
                that you submit via focus groups, contests/sweepstakes, job
                applications, customer support, to other similar means. We may
                also collect any communications between dClimate and you
                (including recording calls made to or by dClimate) and any other
                information you provide to dClimate.
            </li>
        </List>
        <h3>Personal Information Collected Automatically:</h3>
        <p>
            As is true of many digital platforms, we collect certain personal
            information automatically when you visit our online services,
            including:
        </p>
        <List>
            <li>
                <span>Location Data.</span>&nbsp;To help us protect against
                fraud, provide our services to you, and improve our services, we
                collect location data, including current and historical
                information concerning a user’s geographic location data,
                including current and historical information concerning a user’s
                geographic location, GPS location, transaction location, and IP
                addresses that they use in conjunction with our services. We
                collect your precise location data with your permission, and use
                that information, for example, to help protect against fraud by
                detecting and declining debit card transactions that may occur
                far away from your device’s location. We collect information
                about your location through GPS, Wi-Fi, wireless network
                triangulation, or other methods in order to obtain your
                location. We may also approximate your location by your IP
                Address. We also use the Google Maps API to gather information
                about your location. Google uses various technologies to
                determine your location, including IP address, GPS, and other
                sensors that may, for example, provide Google with information
                on nearby devices, Wi-Fi access points, and cell towers (see
                Google’s Privacy Policy to learn more).
            </li>
            <li>
                <span>
                    Usage and Device Data Collected Automatically Through
                    Tracking Technologies.
                </span>
                &nbsp;To provide a personalized and enhanced user experience,
                we, and our third-party partners, automatically collect certain
                types of usage information when you visit or use our Services,
                read our emails, or otherwise engage with us. We typically
                collect this information through a variety of tracking
                technologies, including cookies, Flash objects, web beacons,
                embedded scripts, location identifying technologies, file
                information, and similar technology (collectively, “Tracking
                Technologies”). For example, we collect information about your
                device and its software, such as your IP address, browser type,
                Internet service provider, platform type, device type, operating
                system, data and time stamp, unique ID (that allows us to
                uniquely identify your browsers, mobile device, or your
                account), and other similar information. We use Tracking
                Technologies to help ensure that you have a high-quality
                experience on our Services. We also use Tracking Technologies to
                aid in the targeted advertising of our services on other
                platforms. From your device manufacturer, we also may collect
                the names of other third-party applications that you have
                downloaded on your device.
            </li>
        </List>
        <p>
            If you prefer, you can change your cookie settings. Some browsers
            have options that allow the user to control whether the browser will
            accept cookies, reject cookies, or notify the user each time a
            cookie is sent. You may elect to reject cookies by adjusting your
            settings but doing so will limit the range of features available to
            you on our Services and other websites. Be sure to read any
            information provided by our browser developer to understand the
            limitations of these settings, as they may differ from browser to
            browser and may not apply to all technologies. You may also set your
            email options to prevent the automatic downloading of images that
            may contain technologies that would allow us to know whether you
            have accessed our email, used our Services, or performed certain
            functions with it.
            <br />
            Our Services are also occasionally use “local shared objects” (also
            known as “Flash cookies”). Like browser cookies, Flash cookies may
            be used for coordinating content delivery, website functionality,
            maintaining preferences, advertising, or analytics. Unlike browser
            cookies, “Flash cookies” are not stored in the browser.
        </p>
    </section>
);

export default TypesOfInfoSection;
