import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import internalLinks from "./hash.json";

const Subheader = styled.h2`
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 18px;
`;

const List = styled.ol`
    list-style-type: decimal;
    padding: 18px 36px;
`;

const DisclosureSection = (): JSX.Element => (
    <section id={internalLinks.disclosure}>
        <Subheader>3. Disclosures of Personal Information</Subheader>
        <p>
            dClimate is committed to maintaining your trust, and we want you to
            understand when and with whom we may share the personal information
            we collect. We may share your personal information in the instances
            below. For further information on your choices regarding your
            personal information, see the&nbsp;
            <Link to={`#${internalLinks.control}`}>
                “Control Over Your Information”
            </Link>
            &nbsp; section below. We do not sell or rent your personal
            information to third parties, aside from substantial corporate
            transactions (described below).
        </p>
        <List>
            <li>
                <span>
                    Authorized third-party vendors and service providers.
                </span>
                &nbsp;We share personal data with certain authorized and vetted
                contractors, subcontractors, third-party vendors, and service
                providers who help us run and protect our business. This
                includes sending emails, conducting business analytics,
                marketing, and data processing. For example, we may share
                personal data with service providers that identify and serve
                targeted advertisements or provide mailing services, contest
                fulfillment, web hosting, or analytic services.
            </li>
            <li>
                <span>dClimate affiliates.</span>&nbsp;We share personal data
                with other companies owned or controlled by dClimate Inc. These
                companies will use your personal information in the same way
                that we do under the United States Privacy Policy.
            </li>
            <li>
                <span>Substantial corporate transactions.</span>&nbsp;We may
                share personal data in connection with a substantial corporate
                transaction, such as the sale of a website, a merger,
                consolidation, reorganization, financing, change or control or
                acquisition of all or a portion of our business by another
                company or third party, asset sale, initial public offering, or
                in the unlikely event of bankruptcy or similar proceeding.
            </li>
            <li>
                <span>Legal purposes.</span>&nbsp;We disclose personal data to
                respond to subpoenas, court orders, legal process,
                law-enforcement requests, legal claims, or government inquiries
                and to protect and defend the rights, interests, safety, and
                security of dClimate, our affiliates, users or the public.
            </li>
            <li>
                <span>With your consent.</span>&nbsp;We share personal data for
                any other purposes disclosed to you with your consent. We may
                also share information with others in an aggregated or otherwise
                anonymized form that does not reasonably identify you directly
                as an individual.
            </li>
        </List>
    </section>
);

export default DisclosureSection;
