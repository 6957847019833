import React from "react";
import styled from "styled-components";

import internalLinks from "./hash.json";

const Subheader = styled.h2`
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 18px;
`;

const List = styled.ol`
    list-style-type: lower-alpha;
    padding: 18px 36px;
`;

const HowWeUseSection = (): JSX.Element => (
    <section id={internalLinks.use}>
        <Subheader>2. How We Use Your Information.</Subheader>
        <p>
            We use and process the information we collect for purposes described
            in the United States Privacy Policy or as otherwise described to you
            on our Service or in connection with our Services. For example, we
            use your information to:
        </p>
        <List>
            <li>
                Create and process your account and deliver the Services to you,
                including to allow you to register for the Services and
                participate in interactive features and for dClimate to
                authenticate your identity, handle billing and account
                management, fulfill our legal and regulatory obligations;
            </li>
            <li>
                Send you transactional information, including confirmations,
                invoices, technical notices, product and services information
                and announcements, software updates, security alerts, support
                and administrative messages, and information about your
                transaction with us;
            </li>
            <li>
                Communicate with you such as to respond to your comments and
                questions, deliver newsletter or other content we believe you
                will be interested in, provide customer service or feedback,
                contact you about amounts owed, conduct surveys, respond to
                employment opportunities for which you’ve applied, notify you
                about upcoming events, or for any other purposes in connection
                with the Services;
            </li>
            <li>
                Conduct research and analytics to understand our visitors and
                customers and tailor our product offerings;
            </li>
            <li>
                Provide you with updates about products and services offered by
                us and selected partners, as well as facilitate new contests,
                sweepstakes, promotions, and rewards;
            </li>
            <li>
                Make suggestions and recommendations to you about things that
                similar to those about which you have inquired or that may
                otherwise be of interest to you, such as providing you with
                advertisements based on your interests and activity on the
                Services;
            </li>
            <li>
                Monitor, administer, and enhance our Services, including
                troubleshooting, data analysis, testing, research, statistical,
                and survey purposes;
            </li>
            <li>
                Enhance the safety and security of our Services, business, and
                users, and investigate or provide notice of fraud or unlawful or
                criminal activity;
            </li>
            <li>
                Perform audits and protect or exercise our legal rights or
                defend against legal claims, including to enforce and carry out
                contracts and agreements; and
            </li>
            <li>Comply with applicable laws and legal obligations.</li>
        </List>
    </section>
);

export default HowWeUseSection;
