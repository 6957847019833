import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import internalLinks from "./hash.json";

const Title = styled.h1`
    text-align: center;
    font-size: 36px;
    margin-bottom: 24px;
`;

const List = styled.ol`
    list-style-type: decimal;
    padding: 18px 36px;
`;

const Introduction = (): JSX.Element => (
    <section>
        <Title>dClimate Inc. United States Privacy Policy</Title>
        <p>
            At dClimate Inc., we take privacy and security concerns seriously.
            This United States Privacy Policy outlines how dClimate Inc. and its
            affiliates (collectively, “dClimate”) process the information we
            collect though our websites, mobile apps, and other services we
            provide to you. If you are a California resident, please also see
            the “Notice to California Residents” section below.
        </p>
        <br />
        <p>Click on the links below to jump to specific sections:</p>
        <List>
            <li>
                <Link to={`#${internalLinks.types}`}>
                    Types of information collected and how we collect them
                </Link>
            </li>
            <li>
                <Link to={`#${internalLinks.use}`}>
                    How we use your information
                </Link>
            </li>
            <li>
                <Link to={`#${internalLinks.disclosure}`}>
                    Disclosures of Personal Information
                </Link>
            </li>
            <li>
                <Link to={`#${internalLinks.tplinks}`}>Third-Party Links</Link>
            </li>
            <li>
                <Link to={`#${internalLinks.tpads}`}>
                    Third-Party Tracking and Online Advertising
                </Link>
            </li>
            <li>
                <Link to={`#${internalLinks.control}`}>
                    Control Over Your Information
                </Link>
            </li>
            <li>
                <Link to={`#${internalLinks.ca}`}>
                    Notice to California Residents
                </Link>
            </li>
            <li>
                <Link to={`#${internalLinks.transfer}`}>
                    Transfer of Personal Information
                </Link>
            </li>
            <li>
                <Link to={`#${internalLinks.changes}`}>
                    Changes to this Policy
                </Link>
            </li>
        </List>
    </section>
);

export default Introduction;
