import React from "react";
import styled from "styled-components";

import ExternalLink from "~/components/common/ExternalLink";
import internalLinks from "./hash.json";

const Subheader = styled.h2`
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 18px;
`;

const TpAdSection = (): JSX.Element => (
    <section id={internalLinks.tpads}>
        <Subheader>5. Third-Party Tracking and Online Advertising</Subheader>
        <p>
            We use Google Analytics to recognize you and link your devices when
            you use our Services on your browser or mobile device, log on to
            your accounts on our Services, or otherwise engage with us. We share
            a unique identifier, like a user ID or hashed email address, with
            Google to facilitate the Services. Google Analytics allows us to
            better understand how our users interact with our Services and to
            tailor our advertisements and content to you. For information on how
            Google Analytics collects and processes data, as well as how you can
            control information sent to Google, see “How Google uses data when
            you use our partners’ sites of apps” linked here:&nbsp;
            <ExternalLink to="https://www.google.com/policies/privacy/partners/">
                https://www.google.com/policies/privacy/partners/
            </ExternalLink>
            . You can learn about Google Analytics’ currently available
            opt-outs, including the Google Analytics Browser Ad-On, here:&nbsp;
            <ExternalLink to="https://tools.google.com/dlpage/gaoptout/">
                https://tools.google.com/dlpage/gaoptout/
            </ExternalLink>
            . We may use certain forms of display advertising and other advanced
            features through Google Analytics, such as Remarketing with Google
            Analytics, Google Display Network Impression Reporting. These
            features let us to use first-party cookies (such as the Google
            Analytics cookie) and third-party cookies (such as the DoubleClick
            advertising cookie) or other third-party cookies together to inform,
            optimize, and opt-out of certain Google advertising products by
            visiting the Google Ads Preference Manager, currently available
            at&nbsp;
            <ExternalLink to="https://google.com/ads/preferences">
                https://google.com/ads/preferences
            </ExternalLink>
            , or by visiting NAI’s online resources at&nbsp;
            <ExternalLink to="https://www.networkadvertising.org/choices">
                https://www.networkadvertising.org/choices
            </ExternalLink>
            .
        </p>
    </section>
);

export default TpAdSection;
