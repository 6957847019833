import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";

import Layout from "~/components/Layout";
import { Colors } from "~/components/ThemeVariables";
import Introduction from "~/components/privacy/Introduction";
import TypesOfInfoSection from "~/components/privacy/TypesOfInfoSection";
import HowWeUseSection from "~/components/privacy/HowWeUseSection";
import DisclosureSection from "~/components/privacy/DisclosureSection";
import TpLinkSection from "~/components/privacy/TpLinkSection";
import TpAdSection from "~/components/privacy/TpAdSection";
import CaliSection from "~/components/privacy/CaliSection";
import TransferSection from "~/components/privacy/TransferSection";
import ChangeSection from "~/components/privacy/ChangeSection";

const Page = styled.div`
    display: flex;
    justify-content: center;
    padding: 50px 30px 100px;
`;

const Container = styled.div`
    display: grid;
    grid-template-columns: minmax(auto, 1248px);
    row-gap: 24px;
    place-items: center;
    color: ${Colors.WHITE};

    a {
        color: inherit;
    }
`;

const PrivacyPage = (): JSX.Element => (
    <>
        <Helmet>
            <title>dClimate - Privacy Policy</title>
            <meta
                name="description"
                content="First Decentralized Network for Climate Data, Forecasts, and Models"
            />
            <meta name="og:title" content="dClimate - Privacy Policy" />
            <meta
                property="og:description"
                content="First Decentralized Network for Climate Data, Forecasts, and Models"
            />
            <meta name="twitter:title" content="dClimate - Privacy Policy" />
            <meta
                name="twitter:description"
                content="First Decentralized Network for Climate Data, Forecasts, and Models"
            />
        </Helmet>
        <Layout>
            <Page>
                <Container>
                    <Introduction />
                    <TypesOfInfoSection />
                    <HowWeUseSection />
                    <DisclosureSection />
                    <TpLinkSection />
                    <TpAdSection />
                    <CaliSection />
                    <TransferSection />
                    <ChangeSection />
                </Container>
            </Page>
        </Layout>
    </>
);

export default PrivacyPage;
