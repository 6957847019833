import React from "react";
import styled from "styled-components";

import ExternalLink from "~/components/common/ExternalLink";
import internalLinks from "./hash.json";

const Subheader = styled.h2`
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 18px;
`;

const CaliSection = (): JSX.Element => (
    <section id={internalLinks.ca}>
        <Subheader>7. Notice to California Residents</Subheader>
        <p>
            This section provides additional details about the personal
            information we collect about California residents and the rights
            afforded to them under the&nbsp;
            <ExternalLink to="https://leginfo.legislature.ca.gov/faces/codes_displayText.xhtml?division=3.&part=4.&lawCode=CIV&title=1.81.5">
                California Consumer Privacy Act
            </ExternalLink>
            &nbsp;(“CCPA”). Please note that the CCPA does not apply to what is
            referred to as nonpublic information collected by financial
            institutions (like dClimate), as that information is subject instead
            to other financial privacy laws. As a result, the CCPA does not
            apply to most of the information that dClimate collects.
            <br />
            <br />
            Subject to certain limitations and exceptions, the CCPA provides
            California residents the right to request more details about
            categories and specific elements of personal information we collect,
            to delete their personal information, to opt out of any “sales” that
            may be occurring, and to not be discriminated against for exercising
            these rights. We do not sell information about you to third parties.
            <br />
            <br />
            We collect the following categories of personal information subject
            to the CCPA: identifiers (such as email address and IP address),
            approximate geolocation information, and internet or other
            electronic network activity information (such as browsing history
            and related usage data).
            <br />
            <br />
            California residents may make a consumer rights request for access
            to certain personal information subject to the CCPA, not otherwise
            exempted as nonpublic information collected by financial
            institutions, by sending an email to privacy@dClimate.net. We may
            verify the request by asking you to provide information that matches
            information we have on file about you. You can also designate an
            authorized agent to exercise these rights on your behalf, but we
            will require proof that the person is authorized to act on your
            behalf and may also still ask you to verify your identity with us
            directly.
        </p>
    </section>
);

export default CaliSection;
