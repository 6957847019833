import React from "react";
import styled from "styled-components";

import internalLinks from "./hash.json";

const Subheader = styled.h2`
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 18px;
`;

const ChangeSection = (): JSX.Element => (
    <section id={internalLinks.changes}>
        <Subheader>9. Changes to this Policy</Subheader>
        <p>
            This United States Privacy Policy will evolve with time, and when we
            update it, we will revise the “Effective Date” above and post the
            new Policy. To stay informed of our privacy practices, we recommend
            you review the Policy on a regulator basis as you continue to use
            our Services.
            <br />
            <br />
            Effective Date: 12/27/2021
        </p>
    </section>
);

export default ChangeSection;
